import React from 'react';
import { Link } from 'gatsby';
import '../styles/pagination.scss';
import { pagination } from '../backend/functions';



function Pagination({ pageNumber, total, limit }) {
    const lastPage = Math.ceil(total / limit);
    const paginationArr = pagination(pageNumber, lastPage);

    return (
        <div className="pagination-div">
            <div className="pagination-item">
                <ul>
                    {pageNumber > 1 && (
                         <Link to={pageNumber - 1 === 1 ? '/' : `/page/${pageNumber - 1}`}>
                             <li>Previous</li>
                         </Link>
                      )}

                    {paginationArr.length > 1 &&
                        paginationArr.map((item, index) => {
                            if (item !== '...') {
                                return (
                                    <Link
                                        to={item === 1 ? '/' : `/page/${item}`}
                                        key={index}
                                    >
                                        <li
                                            className={
                                                item === pageNumber
                                                    ? 'active'
                                                    : ''
                                            }
                                        >
                                            {item}
                                        </li>
                                    </Link>
                                );
                            }
                            return <li key={index}>{item}</li>;
                        })}
                        
                    {pageNumber < lastPage && (
                        <Link to={pageNumber + 1 === 1 ? '/' : `/page/${pageNumber + 1}`}>
                            <li>Next</li>
                        </Link>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default Pagination;
