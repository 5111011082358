import React, { useState } from 'react';
import { graphql } from 'gatsby';

import CategoriesMenu from '../comp/categoriesMenu';
import CardBox from '../comp/cardBox';
import HeadNav from '../comp/headNav';
import SEO from '../comp/seo';
import '../styles/home.scss';
import Pagination from '../comp/pagination';
import { Input } from 'antd';
import { advancedSearch } from '../backend/functions';

const { Search } = Input;

const PageTemplate = ({ data, pageContext }) => {
    const [list, setList] = useState(data.apisArray.nodes);
    const [timer, setTimer] = useState(null);

    // const trending = data.trending.nodes;
    // const page = pageContext.pageNumber;
    // const categoryList = data.categories.group.sort(compare);
    const title =
        'KPI Directory: Explore Top Metrics and Key Performance Indicators for Your Business';
    const description =
        'Comprehensive list of over 1000 key metrics and KPIs for business leaders and analysts, spanning sales, marketing, SaaS, manufacturing, e-commerce, social media, customer service, project management, finance, and more.';

    const onSearch = (e) => {
        clearTimeout(timer);
        const { value } = e.target;
        const newTimer = setTimeout(async () => {
            try {
                if (value === '') {
                    setList(data.apisArray.nodes);
                    return;
                }
                var requestOptions = {
                    method: 'GET',
                    redirect: 'follow',
                };

                const localData = localStorage.getItem('searchData'); // Replace 'yourKey' with an appropriate key

                if (localData) {
                    // If data is available locally, use it for searching
                    const { data } = JSON.parse(localData);
                    setList([...advancedSearch(value, data)]);
                } else {
                    // If data is not available locally, make the API call
                    fetch(
                        `https://v1.appbackend.io/v1/rows/Wl2gTs7yCFsB?limit=10000`,
                        requestOptions
                    )
                        .then((response) => response.text())
                        .then((result) => {
                            const { data } = JSON.parse(result);

                            // Save the data in local storage for future use
                            localStorage.setItem(
                                'searchData',
                                JSON.stringify({ data })
                            );
                            setList([...advancedSearch(value, data)]);
                        })
                        .catch((error) => console.log('error', error));
                }
            } catch (error) {
                console.error(error);
                // handle error
            }
        }, 1000);

        setTimer(newTimer);
    };
    return (
        <>
            <HeadNav />
            <SEO
                title={title}
                description={description}
                keywords={[
                    'public',
                    'apis',
                    'development',
                    'free',
                    'coding',
                    'movie',
                    'ip',
                    'location',
                    'open data',
                    'games',
                    'movies',
                ]}
            />
            <div className="container">
                <div className="home-layout">
                    <CategoriesMenu activeCategory="" />
                    <main className="main">
                        <div className="input-wrapper">
                            <input
                                onChange={onSearch}
                                placeholder="Search metrics and KPIs for your business"
                                style={{ width: '100%' }}
                                className="search-input"
                            />
                        </div>
                        <br />
                        {/* <h2 className="pageTitle">
                                          A collective list of 1000+ Metrics and KPIs
                                    </h2> */}
                        <div className="api-list">
                            {list.map((api, key) => (
                                <CardBox key={key} api={api} cardKey={key} />
                            ))}
                        </div>
                        <Pagination {...pageContext} />
                    </main>
                </div>
            </div>
        </>
    );
};

export default PageTemplate;

export const pageQuery = graphql`
    query($skip: Int, $limit: Int) {
        apisArray: allPaApis(
            sort: { fields: createdAt, order: ASC }
            filter: { title: { ne: null } }
            skip: $skip
            limit: $limit
        ) {
            nodes {
                id
                category_id
                category
                published_at
                description
                website
                slug
                title
                createdAt
                logo
                keywords
            }
        }
    }
`;
