export const compare = function (a, b) {
    if (a.totalCount < b.totalCount) {
        return 1;
    }
    if (a.totalCount > b.totalCount) {
        return -1;
    }
    return 0;
};

export const pagination = function (c, m) {
    const current = c;
    const last = m;
    const delta = 2;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
            range.push(i);
        }
    }

    for (const i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
};

export const advancedSearch = (query, dataArray) => {
    // Convert the query to lowercase for case-insensitive search
    const lowercaseQuery = query.toLowerCase();

    // Split the query into individual keywords
    const keywords = lowercaseQuery.split(' ');

    // Use the filter method to find matches based on title or description for each keyword
    const matches = dataArray.filter((item) => {
        const itemName = item.title.toLowerCase();
        const itemDescription = item.description.toLowerCase();
        const itemKeywords = item.keywords ? item.keywords.toLowerCase() : '';

        // Check if either the name or description contains all the keywords
        return keywords.every(
            (keyword) =>
                itemName.includes(keyword) || itemDescription.includes(keyword) || itemKeywords.includes(keyword)
        );
    });

    // Sort the matches based on relevance (you may need to customize this sorting logic)
    const sortedMatches = matches.sort((a, b) => {
        // You can customize the sorting logic based on your requirements
        // For example, you might want to prioritize exact matches over substring matches
        const exactMatchA = keywords.every((keyword) =>
            a.title.toLowerCase().includes(keyword)
        );
        const exactMatchB = keywords.every((keyword) =>
            b.title.toLowerCase().includes(keyword)
        );

        if (exactMatchA && !exactMatchB) {
            return -1;
        } else if (!exactMatchA && exactMatchB) {
            return 1;
        } else {
            // Use a default sorting logic if both are exact matches or both are not exact matches
            // For example, you can sort alphabetically by title
            return a.title.localeCompare(b.title);
        }
    });

    return sortedMatches;
};
